import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component:  () => import('../views/HomeView.vue'),
    redirect: "/home",
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('../views/nnzmrIndex/index.vue')
    }]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router